import React from "react";

const NotFound = () => {
	return (
		<div
			style={{
				marginTop: "50px",
				textAlign: "center",
				color: "black",
				fontSize: "25px",
				fontWeight: "500",
			}}>
			Not Found. Try something else.
		</div>
	);
};

export default NotFound;
