import * as firebase from "firebase/app";

// FIREBASE FEATURE
import "firebase/database";
import "firebase/storage";
import "firebase/auth";

// firebaseConfig removed for security purpose
const firebaseConfig = {
	apiKey: "AIzaSyCPC5LrP6x_WTVgiqNkR74rneLeYhkTQ0A",
	authDomain: "abhishekminz-portfolio.firebaseapp.com",
	databaseURL: "https://abhishekminz-portfolio.firebaseio.com",
	projectId: "abhishekminz-portfolio",
	storageBucket: "abhishekminz-portfolio.appspot.com",
	messagingSenderId: "780821861284",
	appId: "1:780821861284:web:a1ac32f4cc2ccd41596731",
	measurementId: "G-L6VBSVXPDX",
};

firebase.initializeApp(firebaseConfig);

const database = firebase.database();
const storage = firebase.storage();
const firebaseAuth = firebase.auth();

export { database, storage, firebaseAuth };
